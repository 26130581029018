<template>
  <div class="page">
    <head-banner></head-banner>

    <!-- <b-container v-if="isMobile" class="mb-0">
      <b-row>
        <b-col class="px-0" offset-md="1" md="10" lg="7">
          <head-logo></head-logo>
        </b-col>
      </b-row>
    </b-container> -->
    <b-container class="mt-3">
      <b-row>
        <!-- <b-col offset-md="1" md="10" lg="7"> -->
        <b-col cols="12" lg="8">
          <h4 class="py-3">第壹生育集團（FFPGS）</h4>
          <p class="mt-4 mt-md-0">
            第壹生育集團致力於為臺灣客人提供高質量助孕生育服務，填補市場空白。作為亚太華地區生育服務的先驅，第壹生育集團享有悠久聲譽，並擁有市場最佳成功率。十多年來，第壹生育集團已建立起完善的內部研發能力，可以為不育夫婦、單親父母和LGBT人群提供全面優質的生育服務。
          </p>
          <div class="image-section__1">
            <b-img-lazy
              src="@/assets/image/img_01.png"
              alt="image"
            ></b-img-lazy>
            <b-img-lazy
              src="@/assets/image/img_02.png"
              alt="image"
            ></b-img-lazy>
          </div>
          <p class="mt-3">
            吉爾吉斯斯坦第壹試管中心創辦於2019年，位於比什凱克。
          </p>
          <p>
            吉爾吉斯共和國是一個位於中亞的內陸國家，其中吉爾吉斯族占72.8%，東邊緊鄰中國。比什凱克是吉爾吉斯斯坦的首都和最大城市。
          </p>
          <p>第壹試管中心於2021年正式拿到吉爾吉斯斯坦衛生局的合法批文。</p>
          <div>
            <b-img-lazy
              src="@/assets/image/img_11.png"
              alt="合法批文原件"
            ></b-img-lazy>
          </div>
          <div>
            <b-img-lazy
              src="@/assets/image/img_12.png"
              alt="合法批文译本"
            ></b-img-lazy>
          </div>
        </b-col>
        <!-- <b-col v-if="!isMobile" md="8" offset-md="1" lg="4" offset-lg="0">
          <div class="page-section__contact px-md-0 px-lg-3 py-md-4 pt-lg-0">
            聯繫我們
          </div>
          <div class="px-lg-3">
            <contact-form></contact-form>
          </div>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import HeadBanner from "@/components/AboutHead";
// import ContactForm from "./components/ContactForm";
import HeadBanner from "@/components/HeadLogo";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Aboutus",
  components: { HeadBanner },
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
      userType: (state) => state.user.userType,
    }),
    ...mapGetters("user", { userInfo: "userInfo" }),
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.page {
  margin-bottom: 60px;

  color: #000;
  font-size: 1.25em;
  white-space: pre-wrap;
  text-indent: 0em;

  img {
    display: block;
    width: 100%;
    margin: 10px 0px;
  }

  .image-section__1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    img {
      width: 48%;
      height: 140px;
      object-fit: cover;
    }
  }

  .page-section__contact {
    font-weight: bold;
  }
}
</style>
