<template>
  <div class="page pb-5">
    <b-container>
      <b-row>
        <b-col offset-md="1" md="7">
          <div class="page__title">聯繫我們</div>
          <div class="mt-4">
            <contact-form
              :btnfixed="isMobile"
              :btnblock="isMobile"
              @sumbit="goback"
            ></contact-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ContactForm from "./components/ContactForm";
import { mapState } from "vuex";

export default {
  name: "Contactus",
  components: { ContactForm },
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  margin-top: 0px;

  .page__title {
    font-weight: bold;
  }
}
</style>
