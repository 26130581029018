<template>
  <div class="contact-form">
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-1"
        label="姓名（必填）:"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.name"
          required
          placeholder=""
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="電郵地址（必填）:"
        label-for="input-2"
        description=""
      >
        <b-form-input
          id="input-2"
          v-model="form.email"
          type="email"
          required
          placeholder=""
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="電話號碼（必填）:"
        label-for="input-3"
      >
        <b-form-input
          id="input-3"
          v-model="form.mobile"
          type="tel"
          required
          placeholder=""
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="居住地區（必填）:"
        label-for="input-4"
      >
        <b-form-input
          id="input-4"
          v-model="form.address"
          required
          placeholder=""
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-5" label="邀請碼:" label-for="input-5">
        <b-form-input
          id="input-5"
          v-model="form.inviteCode"
          placeholder=""
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-6" label="留言訊息:" label-for="input-6">
        <b-form-textarea
          id="input-6"
          v-model="form.message"
          placeholder=""
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <div class="mt-4">
        <div v-if="alertTips">
          <b-alert v-model="showAlert" :variant="alertVariant" dismissible>
            {{ alertTips }}
          </b-alert>
        </div>

        <div :class="btnClassObj">
          <b-button
            squared
            :block="btnblock"
            :disabled="disabled"
            size="lg"
            type="submit"
            variant="primary"
          >
            <b-spinner v-if="isloading" small class="mr-2"></b-spinner>
            <span>提交</span>
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { saveContactInfo } from "@/utils/api/common";

export default {
  name: "ContactForm",
  props: {
    btnfixed: {
      type: Boolean,
      default: false,
    },
    btnblock: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isloading: false,
      disabled: false,
      showAlert: false,
      alertVariant: null,
      alertTips: null,
      form: {
        name: "",
        email: "",
        mobile: "",
        address: "",
        inviteCode: "",
        message: "",
      },
    };
  },
  computed: {
    btnClassObj() {
      return this.btnfixed ? "btn-fixed__bottom" : "";
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      // alert(JSON.stringify(this.form));

      if (this.isloading) return;
      this.isloading = true;
      this.disabled = true;

      const params = {
        customerName: this.form.name,
        email: this.form.email,
        phone: this.form.mobile,
        address: this.form.address,
        inviteCode: this.form.inviteCode,
        content: this.form.message,
      };

      var that = this;

      saveContactInfo(params)
        .then((res) => {
          console.log(res);
          that.isloading = false;
          that.alertVariant = "success";
          that.alertTips = "已成功發送";
          that.showAlert = true;
          setTimeout(() => {
            that.onReset();
            that.$emit("sumbit", {});
          }, 1600);
        })
        .catch((error) => {
          console.log(error);
          that.isloading = false;
          that.alertVariant = "danger";
          that.alertTips = "提交失敗，請重試!";
          that.showAlert = true;
          setTimeout(() => {
            that.onReset();
          }, 1600);
        });
    },

    onReset() {
      // evt.preventDefault();
      this.isloading = false;
      this.disabled = false;
      this.alertVariant = null;
      this.alertTips = null;
      this.showAlert = false;
      // this.show = false;
      // this.$nextTick(() => {
      //   this.show = true;
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.contact-form {
  font-size: 0.8em !important;

  // .btn-fixed__bottom {
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  // }
}
</style>
