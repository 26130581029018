<template>
  <b-container>
    <b-row>
      <!-- <b-col class="px-0" cols="12" offset-md="1" md="10" lg="7"> -->
      <b-col class="px-0" cols="12" lg="8">
        <div class="head-logo">
          <img class="bg-image" src="@/assets/image/bg_1.png" />
          <div class="app-logo-name px-3 py-4 py-md-0 pb-md-4 pb-lg-5">
            <div class="head__logo">
              <img class="app-logo" src="@/assets/image/logo.png" />
              <div class="app-name">
                <div class="app-name-txt rainbow-gradient">台灣同性生育</div>
                <div class="app-slogan">幫助LGBT群體生育健康寶寶</div>
              </div>
            </div>
            <div class="head__contact mt-2" @click="gotoContactus">
              <div>
                <b-icon icon="chat" class="mr-1"></b-icon>
                <span class="">聯繫我們</span>
              </div>
              <div class="contact-tel rainbow">Tel / Line / Whatsapp</div>
              <div class="contact-tel rainbow">+66 814680148</div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "HeadLogo",
  methods: {
    gotoContactus() {
      let routeData = this.$router.resolve({ name: "Contactus" });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "Contactus",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.head-logo {
  position: relative;
  overflow: hidden;
  clear: both;

  .bg-image {
    display: block;
    width: 100%;
    height: 100%;
  }

  .app-logo-name {
    position: relative;
    margin-top: -100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .head__logo {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .head__contact {
      cursor: pointer;
      font-size: 0.8rem;
      color: rgba(31, 185, 222, 0.8);
      text-shadow: 0.1px 0.1px 0.5px #fff;
    }

    .app-logo {
      width: 46px;
      height: 46px;
      margin-right: 10px;
    }

    .app-name {
      display: flex;
      flex-direction: column;

      .app-name-txt {
        font-size: 18px;
        font-weight: bold;
      }

      .app-slogan {
        font-size: 12px;
        color: #666;
      }
    }

    .contact-tel {
      font-weight: bold;
    }
  }
}

.rainbow-gradient {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #f22),
    color-stop(0.15, #f2f),
    color-stop(0.3, #22f),
    color-stop(0.45, #2ff),
    color-stop(0.6, #2f2),
    color-stop(0.75, #2f2),
    color-stop(0.9, #ff2),
    color-stop(1, #f22)
  );
  background-image: gradient(
    linear,
    left top,
    right top,
    color-stop(0, #f22),
    color-stop(0.15, #f2f),
    color-stop(0.3, #22f),
    color-stop(0.45, #2ff),
    color-stop(0.6, #2f2),
    color-stop(0.75, #2f2),
    color-stop(0.9, #ff2),
    color-stop(1, #f22)
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
</style>
